import { useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Chessground from "@react-chess/chessground";
import { Chess } from "chess.js";
import UserFailures from "./UserFailures";
import CustomAlert from "../components/CustomAlert";
import ChessMoveIndicator from "../components/ChessMoveIndicator";
import InfoPuzzle from "./InfoPuzzle";
import WindowDimensions from "../components/WindowDimensions"; // Adjust the path if necessary

import {
  getUser,
  getPuzzle,
  getFirstPuzzle,
  checkPuzzleResult,
  getUserFailures,
} from "../integrations/APIClient";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";

import UserSuccesses from "./UserSuccesses";
import UserHints from "./UserHints";
import Promotion from "../components/PromotionComponent";

import React from "react";
import { toDests, toColor, toPgnHistory, game } from "../utils/chessUtils";

// Import puzzles from JSON file
import puzzlesData from "../db/puzzles.json"; // Adjust the path as needed

export default function Play() {
  const [category, setCategory] = useState("basic");
  const [isHintClicked, setIsHintClicked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const puzzleID = useParams().id;
  const puzzle = useRef(null);
  const [gameFen, setGameFen] = useState("8/8/8/8/8/8/8/8");
  const [puzzleFinished, setPuzzleFinished] = useState(false);
  const moveNr = useRef(0);
  const [config, setConfig] = useState({});
  const [showHint, setShowHint] = useState(false);
  const [alertInfo, setAlertInfo] = useState({
    show: false,
    variant: "",
    message: "",
    success: "",
  });
  const [user, setUser] = useState({
    rating: "Loading...",
    points: "Loading...",
    category_rating: {},
  });
  const [promoting, setPromoting] = useState(false);
  const [useLocalPuzzles, setUseLocalPuzzles] = useState(false); // New state variable

  const showAlert = (message, variant) => {
    setAlertInfo({ show: true, variant, message, success });
  };

  const hideAlert = () => {
    setAlertInfo({ ...alertInfo, show: false });
  };

  function checkPromotion(game, orig, dest) {
    const pgn = orig.concat(dest);
    if (!(game.get(orig).type === "p")) return pgn;
    if (
      (game.get(orig).color === "w" && orig[1] == 7 && dest[1] == 8) ||
      (game.get(orig).color === "b" && orig[1] == 2 && dest[1] == 1)
    ) {
      setPromoting(pgn);
      return false;
    }
    return pgn;
  }

  function promotion(pgn) {
    setPromoting(false);
    makeUserMove(pgn);
  }

  function updateGame(fen) {
    const newConfig = {
      fen: fen,
      orientation:
        puzzle.current.fenPosition.split(" ")[1] === "w" ? "black" : "white",
      turnColor: toColor(game),
      movable: {
        color: toColor(game),
        dests: toDests(game),
        events: {
          after: userMove,
        },
      },
    };

    setConfig(newConfig);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await getUser();
        setUser(userData);
      } catch (error) {
        setUser(null);
      }

      let puzzleData = null;

      if (puzzleID) {
        try {
          await getUserFailures("crushing");
        } catch (error) {
          console.error(error);
        }
        try {
          puzzleData = await getPuzzle(puzzleID);
        } catch (error) {
          console.error(error);
        }
      } else {
        try {
          puzzleData = await getFirstPuzzle();
          if (puzzleData && puzzleData["id"]) {
            window.history.pushState("", "", "/play/" + puzzleData["id"]);
          } else {
            console.error("Puzzle ID is not available");
          }
        } catch (error) {
          console.error(error);
        }
      }

      if (!user || !puzzleData || !puzzleData.fenPosition) {
        // If user not logged in or puzzle data not available, load from puzzles.json
        loadPuzzleFromJson();
      } else {
        // Proceed with puzzleData
        puzzle.current = puzzleData;

        if (puzzle.current.after_pgn) {
          if (typeof puzzle.current.after_pgn === "string") {
            puzzle.current.after_pgn = puzzle.current.after_pgn.split(" ");
          } else if (Array.isArray(puzzle.current.after_pgn)) {
            // Do nothing
          } else {
            console.error("after_pgn is in an unexpected format");
            puzzle.current.after_pgn = [];
          }
        } else {
          console.error("after_pgn is not available in the puzzle data");
          puzzle.current.after_pgn = [];
        }

        if (puzzle.current.fenPosition) {
          setGameFen(puzzle.current.fenPosition);
          game.load(puzzle.current.fenPosition);
        } else {
          console.error("fenPosition is not available in the puzzle data");
        }

        setConfig({ orientation: game.turn() === "b" ? "white" : "black" });
        updateGame(puzzle.current.fenPosition);
        computerMove(true);
      }
    };

    fetchData();
  }, []);

  function loadPuzzleFromJson() {
    setUseLocalPuzzles(true);
    const randomIndex = Math.floor(Math.random() * puzzlesData.length);
    const data = puzzlesData[randomIndex];
    puzzle.current = data;

    if (puzzle.current.after_pgn) {
      if (typeof puzzle.current.after_pgn === "string") {
        puzzle.current.after_pgn = puzzle.current.after_pgn.split(" ");
      } else if (Array.isArray(puzzle.current.after_pgn)) {
        // Do nothing
      } else {
        console.error("after_pgn is in an unexpected format");
        puzzle.current.after_pgn = [];
      }
    } else {
      console.error("after_pgn is not available in the puzzle data");
      puzzle.current.after_pgn = [];
    }

    if (puzzle.current.fenPosition) {
      setGameFen(puzzle.current.fenPosition);
      game.load(puzzle.current.fenPosition);
    } else {
      console.error("fenPosition is not available in the puzzle data");
    }

    setConfig({ orientation: game.turn() === "b" ? "white" : "black" });
    updateGame(puzzle.current.fenPosition);
    computerMove(true);
  }

  function makeUserMove(pgn, hint = false) {
    if (useLocalPuzzles) {
      if (hint) {
        setIsHintClicked(true);
        setAlertInfo({
          show: true,
          variant: "warning",
          message: "Here's a hint for you!",
          success: "hint",
          fen: puzzle.current.fenPosition,
          after_pgn: puzzle.current.after_pgn,
          config: config,
        });
        setPuzzleFinished(true);
        return;
      }

      if (pgn === puzzle.current.after_pgn[moveNr.current]) {
        game.move(pgn);
        if (moveNr.current == puzzle.current.after_pgn.length - 1) {
          setAlertInfo({
            show: true,
            variant: "success",
            message: puzzle.current.success_message,
            success: "true",
          });
          updateGame(game.fen());
          setPuzzleFinished(true);
        } else {
          moveNr.current++;
          computerMove();
        }
      } else {
        setAlertInfo({
          show: true,
          variant: "error",
          message: puzzle.current.fail_message,
          success: "false",
        });
        setPuzzleFinished(true);
        updateGame(game.fen());
      }
    } else {
      // Existing code with API calls remains unchanged
      console.log(puzzle.current["id"], toPgnHistory(game));

      if (hint) {
        setIsHintClicked(true);

        // Assuming 'hint' is a boolean indicating a hint request
        checkPuzzleResult(puzzle.current["id"], toPgnHistory(game), true)
          .then((result) => {
            setAlertInfo({
              show: true,
              variant: "warning ",
              user: result.user,
              addedOrRestRating: result.addedOrRestRating,
              puzzleSuccessMessage: "Here's a hint for you!",
              success: "hint",
              fen: puzzle.current.fenPosition,
              after_pgn: puzzle.current.after_pgn,
              config: config,
            });
          })
          .catch((error) => {
            showError(
              error.message || "An error occurred while fetching the hint."
            );
          });
        setPuzzleFinished(true);
        return;
      }

      if (pgn === puzzle.current.after_pgn[moveNr.current]) {
        game.move(pgn);

        if (moveNr.current == puzzle.current.after_pgn.length - 1) {
          checkPuzzleResult(puzzle.current["id"], toPgnHistory(game))
            .then((result) => {
              setAlertInfo({
                show: true,
                variant: "success",
                user: result.user,
                addedOrRestRating: result.addedOrRestRating,
                puzzleSuccessMessage: puzzle.current.success_message,
                success: "true",
              });
            })
            .catch((error) => {
              setAlertInfo({
                show: true,
                variant: "success",
                user: result.user,
                addedOrRestRating: result.addedOrRestRating,
                puzzleSuccessMessage: puzzle.current.success_message,
                success: "error",
              });
            });
          updateGame(game.fen());
          setPuzzleFinished(true);
        } else {
          moveNr.current++;
          computerMove();
        }
      } else {
        checkPuzzleResult(puzzle.current["id"], toPgnHistory(game))
          .then((result) => {
            setAlertInfo({
              show: true,
              variant: "error",
              user: result.user,
              addedOrRestRating: result.addedOrRestRating,
              puzzleSuccessMessage: puzzle.current.fail_message,
              success: "false",
            });
          })
          .catch((error) => {
            showError(error);
          });

        setPuzzleFinished(true);
        updateGame(game.fen());
      }
    }
  }

  function repeat() {
    window.location.reload(false);
  }

  function userMove(orig, dest, metadata) {
    const pgn = checkPromotion(game, orig, dest);
    if (!pgn) return;
    makeUserMove(pgn);
  }

  function computerMove(firstLoad = false) {
    const move = puzzle.current.after_pgn[firstLoad ? 0 : moveNr.current];

    if (firstLoad) {
      moveNr.current = 1;
    } else {
      moveNr.current++;
    }
    game.move(move);
    updateGame(game.fen());
  }

  function nextPuzzle() {
    window.location.replace("/play");
  }

  function hint() {
    setShowHint(!showHint);
    makeUserMove(moveNr.current, true);
  }

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await getUser();
        setUser(userData);
      } catch (error) {
        setUser(null);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    document.body.classList.add("play-active");

    return () => {
      document.body.classList.remove("play-active");
    };
  }, []);

  return (
    <>
      <Row>
        <Col xs={12} sm={8} md={9} lg={8} xl={8} xxl={8} className="   column3">
          <Promotion pgn={promoting} onPromotion={promotion} />
          <Chessground
            contained={false}
            config={config}
            className=" img-fluid ml-5 chesscolumn"
          />
        </Col>
        <Col
          xs={12}
          sm={4}
          md={3}
          lg={4}
          xl={4}
          xxl={4}
          className="infoPuzzle column4 "
        >
          <WindowDimensions />
          {alertInfo.show && (
            <CustomAlert
              variant={alertInfo.variant}
              message={alertInfo.message}
              onClose={hideAlert}
              user={alertInfo.user}
              addedOrRestRating={alertInfo.addedOrRestRating}
              puzzleSuccessMessage={alertInfo.puzzleSuccessMessage}
              success={alertInfo.success}
              fen={alertInfo.fen}
              after_pgn={alertInfo.after_pgn}
            />
          )}
          <Row className="ml-3">
            <Col
              xs={user ? 2 : 3}
              xl={user ? 2 : 3}
              className={user ? "col col2" : "col col1"}
            >
              <UserFailures />
            </Col>
            <Col xs={user ? 3 : 3} xl={user ? 2 : 3} className="col">
              <UserSuccesses />
            </Col>
            <Col xs={user ? 3 : 12} xl={user ? 2 : 3} className="col">
              <UserHints />
            </Col>
          </Row>
          <InfoPuzzle
            puzzle={puzzle}
            user={user}
            config={config}
            nextPuzzle={nextPuzzle}
            puzzleFinished={puzzleFinished}
            repeat={repeat}
            hint={hint}
            isHintClicked={isHintClicked}
            showHint={showHint}
            after_pgn={puzzle.current ? puzzle.current.after_pgn : ""}
            fen={gameFen}
          />{" "}
        </Col>
      </Row>{" "}
    </>
  );
}
